<mat-card>
    <mat-card-header>
      <mat-icon style="cursor: pointer" routerLink="/principal/cartoes-credito">arrow_back</mat-icon>
      <h2>Cadastrar novo cartão de crédito</h2>
    </mat-card-header>
    <mat-card-content>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="alert alert-success">
              <div class="row">
                <div class="col-xs-12 col-md-1">
                  <img src="assets/images/cadeado.png" alt="Seguro" class="cadeado">
                </div>
                <div class="col-xs-12 col-md-11">
                  <h2>Ambiente seguro</h2>
                  <p>Fique tranquilo, seus dados serão transmitidos e processados com criptografia RSA; a mais poderosa da atualidade.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h2>Meus dados</h2>
          </div>
        </div>
        <form [formGroup]="myProfileForm">
          <div class="row">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input type="text" matInput readonly formControlName="nome">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Documento</mat-label>
                <input type="text" matInput readonly mask="CPF_CNPJ" formControlName="documento">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input type="text" matInput readonly formControlName="email">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Celular</mat-label>
                <input type="text" matInput readonly formControlName="telCelular" mask="(99) 99999-9999 | (99) 9999-9999">
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-12">
            <h2>Meus contratos</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-table [dataSource]="dataSourceContrato" [displayedColumns]="columnsContrato" defaultEmptyResultMessage="Nenhum contrato encontrado"></app-table>
          </div>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-12">
            <h2>Dados básicos do cartão</h2>
          </div>
        </div>
        <form [formGroup]="formCreditCard">
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Número do cartão</mat-label>
                <input (blur)="getCreditCardFlag()" type="text" matInput required formControlName="number" mask="0000 0000 0000 0000">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Nome impresso no cartão</mat-label>
                <input type="text" matInput required formControlName="holderName">
                <mat-hint>Coloque exatamente como está impresso no cartão</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-md-2">
              <mat-form-field>
                <mat-label>Validade</mat-label>
                <input placeholder="Ex.: 11/2030" matInput required type="text" mask="M0/0000" formControlName="due">
                <mat-hint>Ex.: 11/2030</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-md-2">
              <mat-form-field>
                <mat-label>CVV</mat-label>
                <input type="text" required matInput mask="000" formControlName="cvv">
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-12">
            <h2>Dados para cobrança</h2>
          </div>
        </div>
        <form [formGroup]="formBillingCard">
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input type="text" matInput formControlName="billingName" required>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>CPF/CNPJ</mat-label>
                <input type="text" matInput formControlName="billingCpfCnpj" required mask="CPF_CNPJ">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input type="email" matInput formControlName="billingEmail" required>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Telefone</mat-label>
                <input type="text" matInput formControlName="billingPhone" required mask="(00) 00000-0000">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h2>Endereço de cobrança</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>CEP</mat-label>
                <input (blur)="fillCep()" type="text" matInput formControlName="billingZipCode" required mask="00000-000">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>Endereço</mat-label>
                <input type="text" matInput formControlName="billingAddress" required>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-2">
              <mat-form-field>
                <mat-label>Número</mat-label>
                <input type="text" matInput formControlName="billingNumber" required>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Complemento</mat-label>
                <input type="text" matInput formControlName="billingComplement">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Bairro</mat-label>
                <input type="text" matInput formControlName="billingNeighborhood" required>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Cidade</mat-label>
                <input type="text" matInput formControlName="billingCity" required>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Estado</mat-label>
                <mat-select formControlName="billingState" required>
                  <mat-option value=""></mat-option>
                  <mat-option value="AC">Acre</mat-option>
                  <mat-option value="AL">Alagoas</mat-option>
                  <mat-option value="AP">Amapá</mat-option>
                  <mat-option value="AM">Amazonas</mat-option>
                  <mat-option value="BA">Bahia</mat-option>
                  <mat-option value="CE">Ceará</mat-option>
                  <mat-option value="DF">Distrito Federal</mat-option>
                  <mat-option value="ES">Espírito Santo</mat-option>
                  <mat-option value="GO">Goiás</mat-option>
                  <mat-option value="MA">Maranhão</mat-option>
                  <mat-option value="MT">Mato Grosso</mat-option>
                  <mat-option value="MS">Mato Grosso do Sul</mat-option>
                  <mat-option value="MG">Minas Gerais</mat-option>
                  <mat-option value="PA">Pará</mat-option>
                  <mat-option value="PB">Paraíba</mat-option>
                  <mat-option value="PR">Paraná</mat-option>
                  <mat-option value="PE">Pernambuco</mat-option>
                  <mat-option value="PI">Piauí</mat-option>
                  <mat-option value="RJ">Rio de Janeiro</mat-option>
                  <mat-option value="RN">Rio Grande do Norte</mat-option>
                  <mat-option value="RO">Rondônia</mat-option>
                  <mat-option value="RR">Roraima</mat-option>
                  <mat-option value="SC">Santa Catarina</mat-option>
                  <mat-option value="SP">São Paulo</mat-option>
                  <mat-option value="SE">Sergipe</mat-option>
                  <mat-option value="TO">Tocantins</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h2>Cobranças em aberto</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-table [dataSource]="dataSourceFatura" [displayedColumns]="columnsFatura" defaultEmptyResultMessage="Nenhuma cobrança em aberto encontrada"></app-table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h3>Valor total em aberto: <span class="valor-total">{{valorTotalInadimplente | currency}}</span></h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h2>Confirmação</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <mat-checkbox formControlName="acceptTerms">Aceito os </mat-checkbox><span class="link" (click)="exibirTermosUso()"> termos de uso</span>
            </div>
          </div>
          <div class="row" *ngIf="formBillingCard.get('acceptTerms')?.value">
            <div class="col-12">
              <div class="alert alert-info">
                <h2>Antes de prosseguir, leia atentamente os pontos abaixo:</h2>
                <ul>
                  <li>Confira os dados fornecidos antes de confirmar a operação</li>
                  <li *ngIf="valorTotalInadimplente">Será debitado o valor de <strong>{{valorTotalInadimplente | currency}}</strong> após a confirmação desta operação referente a pagamento(s) em aberto no momento.</li>
                  <li>Ao cadastrar o seu cartão, você concorda que, no dia do vencimento dos contratos acima, serão debitados os valores correspondentes de cada mensalidade.</li>
                  <li>Caso haja alguma falha no processo de cobrança, o cliente deve realizar o pagamento manualmente até o dia do vencimento sob pena de ser considerado inadimplente</li>
                  <li>Caso deseje cancelar o débito automático no cartão de crédito, entre em contato com o setor financeiro.</li>
                  <li>Caso haja algum valor que você considere que foi cobrado de forma indevida, por gentileza, entre em contato com o nosso setor financeiro</li>
                </ul>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-12">
            <button mat-raised-button mat-button color="primary" (click)="post()" [disabled]="formCreditCard.invalid || formBillingCard.invalid">Cadastrar Cartão de Crédito</button>
          </div>
        </div>
      </div>
    </mat-card-content>
</mat-card>
