import {Component, Inject, OnInit} from '@angular/core';
import {PagamentoFaturaService} from "../payment-invoice/payment-invoice.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {lastValueFrom} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {InvoiceNew} from "../models/InvoiceNew";
import {InvoiceService} from "../invoice/invoice.service";

@Component({
  selector: 'app-modal-credit-card',
  templateUrl: './modal-credit-card.component.html',
  styleUrls: ['./modal-credit-card.component.css']
})
export class ModalCreditCardComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    usarCartaoCadastrado: new FormControl(false, [Validators.required]),
    nomeCartao: new FormControl('', [Validators.required]),
    numeroCartao: new FormControl('', [Validators.required]),
    vencimento: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
    documento: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl('', []),
    bairro: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    celular: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    cadastrarRecorrencia: new FormControl(false, [Validators.required]),
  });
  public constructor(
    private faturaService: PagamentoFaturaService,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) protected fatura: InvoiceNew,
    private matDialogRef: MatDialogRef<ModalCreditCardComponent>,
  ) {
  }

  async ngOnInit(): Promise<any> {

  }


  public async preencherCep(): Promise<any> {
    try {
      this.faturaService.showSpinner();
      const dadosCep = await lastValueFrom(this.faturaService.cep(this.form.get("cep")?.value));
      this.form.patchValue({
        cep: dadosCep.cep,
        logradouro: dadosCep.logradouro,
        bairro: dadosCep.bairro,
        cidade: dadosCep.localidade,
        estado: dadosCep.uf,
      });
    } catch (e) {

    } finally {
      this.faturaService.hideSpinner();
    }
  }

  public async pagar(): Promise<any> {
    try {
      this.faturaService.showSpinner();
      await lastValueFrom(this.invoiceService.payInvoiceWithCreditCard(this.fatura.CODIGO_FATURA.toString(), this.form.getRawValue()));
      this.faturaService.toasterSuccess("Aguarde alguns instantes. Estamos realizando o processamento das suas informações.", "Pagamento em processamento");
      this.matDialogRef.close();
    } catch (e) {
      console.error(e);
    } finally {
      this.faturaService.hideSpinner();
    }
  }

  getLogo(bandeira: string): string{
    return '/assets/images/cards/' + bandeira.toLowerCase() + '.svg';
  }
}
