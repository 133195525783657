<mat-card *ngIf="show">
    <mat-card-header class="title-card-header">
        <mat-icon routerLink="/principal/dashboard" style="cursor: pointer">arrow_back</mat-icon>
        <h2>Meus cartões de crédito</h2>
    </mat-card-header>
    <mat-card-content>
        <div class="row" *ngIf="creditCards.length <= 0">
            <div class="col-12">
                <div class="alert alert-info text-center">
                    <strong>Nenhum cartão de crédito cadastrado.</strong>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="creditCards.length > 0">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table">
                        <thead>
                            <tr>
                                <th class="text-center">Cartão</th>
                                <th class="text-center">Vencimento</th>
                                <th class="text-center">Gateway</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let card of creditCards">
                                <td class="text-center"><img class="logo-card" [src]="getLogo(card.BANDEIRA)" alt="">****{{card.ULTIMOS_NUMEROS}}</td>
                                <td class="text-center">{{card.DATA_VENCIMENTO_CARTAO | date: 'MM/yyyy'}}</td>
                                <td class="text-center">{{card.DESCRICAO_GATEWAY_PAGAMENTO}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <button (click)="redirectNew()" mat-button mat-raised-button color="primary" routerLinkActive="principal/cadastro-cartao-credito">Cadastrar novo cartão</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
