import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseServiceService } from '../base-service.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService extends BaseServiceService {

  setNewPassword(newPassword1: string, newPassword2: string): Observable<any> {
    return this.post(this.getUrl('client_area/change_new_password'), {
      newPassword: newPassword1,
      confirmNewPassword: newPassword2
    });
  }

  private rawPost(url: string, body: object): Observable<any>{
    return this.http.post(url, body);
  }

}
