<h1 mat-dialog-title>Pagar com cartão de crédito</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div *ngIf="fatura.CARTAO_CADASTRADO;">
      <div class="row">
        <div class="col-12">
          <h2>Desejo pagar com</h2>
          <mat-radio-group formControlName="usarCartaoCadastrado">
            <mat-radio-button color="primary" [value]="true">Cartão <img class="logo-card" [src]="getLogo(fatura.DADOS_BASICOS_CARTAO?.BANDEIRA ?? '')" [alt]="fatura.DADOS_BASICOS_CARTAO?.BANDEIRA"> final {{fatura.DADOS_BASICOS_CARTAO?.ULTIMOS_NUMEROS}}</mat-radio-button>
            <mat-radio-button color="primary" [value]="false">Cartão de crédito diferente</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div *ngIf="!form.get('usarCartaoCadastrado')?.value || !fatura.CARTAO_CADASTRADO">
      <div class="row">
        <div class="col-12">
          <h2>Dados básicos do cartão</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Número do cartão</mat-label>
            <input type="text" matInput formControlName="numeroCartao" mask="0000 0000 0000 0000">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field>
            <mat-label>Vencimento</mat-label>
            <input type="text" matInput formControlName="vencimento" mask="00/0000">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field>
            <mat-label>CVV</mat-label>
            <input type="text" matInput formControlName="cvv">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>CPF/CNPJ</mat-label>
            <input type="text" matInput formControlName="documento" mask="000.000.000-00 || 00.000.000/0000-00">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Nome impresso no cartão</mat-label>
            <input type="text" matInput formControlName="nomeCartao">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2>Endereço de cobrança</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 col-md-3">
          <mat-form-field>
            <mat-label>CEP</mat-label>
            <input type="text" matInput formControlName="cep" (blur)="preencherCep()" mask="00000-000">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Endereço</mat-label>
            <input type="text" matInput formControlName="logradouro">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field>
            <mat-label>Número</mat-label>
            <input type="text" matInput formControlName="numero">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Complemento</mat-label>
            <input type="text" matInput formControlName="complemento">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Bairro</mat-label>
            <input type="text" matInput formControlName="bairro">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Cidade</mat-label>
            <input type="text" matInput formControlName="cidade">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Estado</mat-label>
            <mat-select formControlName="estado">
              <mat-option value="">Selecione...</mat-option>
              <mat-option value="AC">Acre</mat-option>
              <mat-option value="AL">Alagoas</mat-option>
              <mat-option value="AP">Amapá</mat-option>
              <mat-option value="AM">Amazonas</mat-option>
              <mat-option value="BA">Bahia</mat-option>
              <mat-option value="CE">Ceará</mat-option>
              <mat-option value="DF">Distrito Federal</mat-option>
              <mat-option value="ES">Espírito Santo</mat-option>
              <mat-option value="GO">Goiás</mat-option>
              <mat-option value="MA">Maranhão</mat-option>
              <mat-option value="MT">Mato Grosso</mat-option>
              <mat-option value="MS">Mato Grosso do Sul</mat-option>
              <mat-option value="MG">Minas Gerais</mat-option>
              <mat-option value="PA">Pará</mat-option>
              <mat-option value="PB">Paraíba</mat-option>
              <mat-option value="PR">Paraná</mat-option>
              <mat-option value="PE">Pernambuco</mat-option>
              <mat-option value="PI">Piauí</mat-option>
              <mat-option value="RJ">Rio de Janeiro</mat-option>
              <mat-option value="RN">Rio Grande do Norte</mat-option>
              <mat-option value="RS">Rio Grande do Sul</mat-option>
              <mat-option value="RO">Rondônia</mat-option>
              <mat-option value="RR">Roraima</mat-option>
              <mat-option value="SC">Santa Catarina</mat-option>
              <mat-option value="SP">São Paulo</mat-option>
              <mat-option value="SE">Sergipe</mat-option>
              <mat-option value="TO">Tocantins</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Celular</mat-label>
            <input type="text" matInput formControlName="celular" mask="(00) 00000-0000">
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input type="email" matInput formControlName="email">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-checkbox formControlName="cadastrarRecorrencia">Guardar este cartão para pagamento automático das próximas cobranças</mat-checkbox>
        </div>
      </div>
      <ng-template [ngIf]="form.get('cadastrarRecorrencia')?.value">
        <div class="row">
          <div class="col-12">
            <div class="alert alert-info">
              <h2>Condições para o pagamento automático</h2>
              <ul>
                <li>Ao guardar o cartão de crédito, você concorda expressamente que serão debitados, na data de vencimento de cada contrato ativo, o valor correspondente a cada cobrança gerada aos seus contratos.</li>
                <li>Para cancelar o pagamento automático, entre em contato com o nosso time financeiro</li>
                <li>Recomendamos que acompanhe todo mês os valores que serão cobrados aqui nesta plataforma</li>
                <li>Se já existir um cartão de crédito cadastrado conosco, ele será substituído pelo informado acima</li>
                <li>Caso haja algum valor que você desconheça ou discorde, estamos a postos para esclarecer qualquer dúvida e resolver qualquer problema</li>
              </ul>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-12">
        <br>
        <h1>Valor a pagar: <strong style="color: #1da73c">{{fatura.VALOR_TOTAL | currency}}</strong></h1>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions *ngIf="form.get('usarCartaoCadastrado')?.value; else matDialogForm">
  <button (click)="pagar()" mat-button mat-raised-button color="primary">Confirmar</button>
  <button mat-button mat-dialog-close>Cancelar</button>
</div>
<ng-template #matDialogForm>
  <div mat-dialog-actions>
    <button (click)="pagar()" [disabled]="form.invalid" mat-button mat-raised-button color="primary">Confirmar</button>
    <button mat-button mat-dialog-close>Cancelar</button>
  </div>
</ng-template>
