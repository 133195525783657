import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PagamentoFaturaService} from './payment-invoice.service';
import {InvoiceNew} from '../models/InvoiceNew';
import {MatDialog} from '@angular/material/dialog';
import {DuplicateInvoiceService} from '../duplicate-invoice/duplicate-invoice.service';
import {AppConstants} from '../appConstants';
import {PaymentCheckoutModalComponent} from "../payment-checkout-modal/payment-checkout-modal.component";
import {lastValueFrom} from "rxjs";
import {AutenticacaoExpressaService} from "../autenticacao-expressa/autenticacao-expressa.service";
import {ModalCreditCardComponent} from "../modal-credit-card/modal-credit-card.component";
import {TrocaObrigatoriaSenhaComponent} from "../troca-obrigatoria-senha/troca-obrigatoria-senha.component";
import {LoginService} from "../login/login.service";

@Component({
  selector: 'app-payment-invoice',
  templateUrl: './payment-invoice.component.html',
  styleUrls: ['./payment-invoice.component.css']
})
export class PagamentoFaturaComponent implements OnInit {

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private service: PagamentoFaturaService,
      private boletoService: DuplicateInvoiceService,
      private autenticacaoExpressaService: AutenticacaoExpressaService,
      private loginService: LoginService,
      public dialog: MatDialog,
  ) {
    this.id = this.route.snapshot.params["id"];
    this.route.queryParams.subscribe((params) => {
      this.tokenInvoice = params['token'];
    });
  }

  public invoice: InvoiceNew = {} as InvoiceNew;
  public id: number;
  public tokenInvoice: string = "";
  public constants = AppConstants;

  public form: FormGroup = new FormGroup({
    card_number: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(5)]),
    card_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    expiration_month: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(3)]),
    expiration_year: new FormControl('', [Validators.required]),
    document: new FormControl('', [Validators.required]),
  });

  private async verificarTrocaSenha(): Promise<void> {
    try {
      this.service.showSpinner();
      const teste = await lastValueFrom(this.loginService.verificarTrocaSenha());
      this.service.hideSpinner();
      if(teste){
        await lastValueFrom(this.dialog.open(TrocaObrigatoriaSenhaComponent, {
          disableClose: true
        }).afterClosed());
      }
    } catch (e){
      console.log(e);
    } finally {
      this.service.hideSpinner();
    }
  }

  async ngOnInit(): Promise<any> {
    try {
      this.service.showSpinner();
      if(this.tokenInvoice){
        await this.autenticacaoExpressaService.autenticarToken(this.tokenInvoice);
      }
      await this.loginService.mostrarModalTrocaSenha();
      this.service.showSpinner();
      const res = await lastValueFrom(this.service.getInvoice(this.id));
      this.invoice = res;
      if (res.DATA_PAGAMENTO != null){
        if  (res.DATA_PROCESSAMENTO == null) {
          this.service.toasterSuccess('Esta fatura já consta como paga.' +
            '\n\n' +
            'Aguarde alguns minutos para a atualização de status do seu contrato e dos controles de acesso ' +
            'ao seu box.', 'Parabéns');
        } else {
          this.service.toasterSuccess('Esta fatura já consta como paga.', 'Parabéns');
        }
      } else if (res.ACRESCIMO > 0){
        this.service.toasterWarning('Esta fatura possui acréscimos por estar em atraso. Faça o pagamento para garantir a continuidade do contrato', 'Aviso');
      }
    } catch (e) {
      this.service.toasterError('Erro ao gerar faturamento');
      await this.returnToDashboard();
    } finally {
      this.service.hideSpinner();
    }
  }
  async returnToDashboard(): Promise<any> {
    await this.router.navigate(['principal/dashboard']);
  }
  async proccessCheckout(paymentType: number): Promise<any>{
    this.service.showSpinner();
    try {
      const res = await lastValueFrom(this.service.proccessPaymentCheckout(this.id, paymentType));
      this.service.toasterInfo("Verifique se o seu navegador bloqueou o pop-up", "Importante");
      window.open(res.UrlCheckout ?? "", '_blank');
    } catch (err: any) {
      console.error(err);
      this.service.toasterError(err.error, 'Houve um erro no checkout');
    } finally {
      this.service.hideSpinner();
    }
  }

  async buildCheckout(): Promise<any>{
    try {
      await lastValueFrom(this.dialog.open(ModalCreditCardComponent, {
          width: "100%",
          data: this.invoice,
        }).afterClosed()
      );
    } catch (e: any){
      console.log(e);
      this.service.toasterError(e.error);
    } finally {
      await this.ngOnInit();
    }
  }

  disclaimerAcrescimos(): void {
    this.service.toasterInfo('Eventuais juros e multas de lançamentos atrasados', 'Informaçāo');
  }

  async showBoleto(): Promise<any>{
    try {
      this.service.toasterInfo('Gerando o seu boleto', 'Aguarde');
      this.service.showSpinner();
      const data = await lastValueFrom(this.boletoService.getBankInvoice(this.invoice.CODIGO_BOLETO));
      const file = new Blob([data],{ type: 'text/html' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.service.toasterInfo('Caso seu boleto não esteja visível, verifique se o seu navegador bloqueou algum popup para esta página.', 'Dica');
    } catch (error) {
      console.error(error);
      this.service.toasterError('Erro ao gerar boleto');
    } finally {
      this.service.hideSpinner();
      await this.ngOnInit();
    }
  }

  showNfse(): void {

  }

  copyCodigoBarrasBoleto(): void{
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.invoice.CODIGO_BARRAS_BOLETO;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.service.toasterSuccess('Código de barras copiado!', 'Sucesso');
  }
}
