import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { CreditCardFormComponent } from './credit-card-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
// import {NgxMaskModule} from 'ngx-mask';
import {MatIconModule} from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgxMaskDirective} from "ngx-mask";
import {RouterLink} from "@angular/router";
import {TableModule} from "@cdsistemas/ui";
import { ConfirmacacaoInclusaoCartaoComponent } from './confirmacacao-inclusao-cartao/confirmacacao-inclusao-cartao.component';
import {MatDialogModule} from "@angular/material/dialog";
import { SucessoInclusaoCartaoComponent } from './sucesso-inclusao-cartao/sucesso-inclusao-cartao.component';



@NgModule({
  declarations: [
    CreditCardFormComponent,
    ConfirmacacaoInclusaoCartaoComponent,
    SucessoInclusaoCartaoComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    MatStepperModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    // NgxMaskModule,
    MatIconModule,
    FontAwesomeModule,
    NgxMaskDirective,
    RouterLink,
    TableModule,
    MatDialogModule,
  ]
})
export class CreditCardFormModule { }
