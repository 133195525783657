import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {ViaCep} from './models/ViaCep';
import {getDatabase} from "../environments/environment-functions";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class BaseServiceService {

  constructor(
    protected http: HttpClient,
    protected toaster: ToastrService,
    protected spinner: NgxSpinnerService,
    protected matDialogData: MatDialog,
  ) {
    localStorage.setItem("x-database-group", getDatabase());
  }

  /**
   * Monta o spinner
   */
  showSpinner(): void {
    this.spinner.show();
  }

  /**
   * Remove o spinner
   */
  hideSpinner(): void{
    this.spinner.hide();
  }

  /**
   * Toaster de informação (azul)
   * @param msg
   * @param title
   */
  toasterInfo(msg: string, title?: string): void {
    this.toaster.info(msg, title);
  }

  /**
   * Toaster de sucesso (verde)
   * @param msg
   * @param title
   */
  toasterSuccess(msg: string, title?: string): void{
    this.toaster.success(msg, title);
  }

  /**
   * Toaster de aviso (amarelo)
   * @param msg
   * @param title
   */
  toasterWarning(msg: string, title?: string): void{
    this.toaster.warning(msg, title);
  }

  /**
   * Toaster de erro (vermelho)
   * @param msg
   * @param title
   */
  toasterError(msg: string, title?: string): void{
    this.toaster.error(msg, title);
  }

  cep(cep: string): Observable<ViaCep> {
    return this.http.get('https://viacep.com.br/ws/' + cep + '/json/') as Observable<ViaCep>;
  }



  /**
   * Faz o GET no servidor
   * @param url
   * @protected
   */
  protected get(url: string): Observable<any> {
    return this.http.get(this.getUrl(url), {headers: this.getHeaders()});
  }

  /**
   * @param url
   * @param load
   * @protected
   */
  protected post(url: string, load: object): Observable<any> {
    return this.http.post(this.getUrl(url), load, {headers: this.getHeaders()});
  }

  /***
   * @param url
   * @param load
   * @protected
   */
  protected put(url: string, load: object): Observable<any> {
    return this.http.put(this.getUrl(url), load, {headers: this.getHeaders()});
  }


  /**
   * @param url
   * @protected
   */
  protected delete(url: string): Observable<any> {
    return this.http.delete(this.getUrl(url), {headers: this.getHeaders()});
  }

  /**
   * Retorna os cabecalhos necessários para autenticação
   * @protected
   */
  public getHeaders(): HttpHeaders {
    const headers = {
      'Authorization': "Bearer " + window.localStorage.getItem('x-session-token-ac') ?? "",
      'x-database-group': getDatabase(),
    };
    return new HttpHeaders(headers);
  }

  /**
   * Gera a URL
   * @param url
   * @protected
   */
  public getUrl(url: string): string{
    return new URL(url, environment.baseUrl).href;
  }
}
