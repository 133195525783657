import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import { DashboardService } from '../dashboard/dashboard.service';
import {Contract} from '../models/Contract';
import {Form, FormControl, FormGroup, Validators} from '@angular/forms';
import {CreditCardRequest} from '../models/CreditCardRequest';
import {CreditCardService} from './credit-card.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AutenticacaoExpressaService} from "../autenticacao-expressa/autenticacao-expressa.service";
import {lastValueFrom} from "rxjs";
import {LoginService} from "../login/login.service";
import {MyProfileService} from "../my-profile/my-profile.service";
import {MatDialog} from "@angular/material/dialog";
import {TermosUsoRecorrenciaComponent} from "../termos-uso-recorrencia/termos-uso-recorrencia.component";
import {MatTableDataSource} from "@angular/material/table";
import {Field, FieldBadge} from "@cdsistemas/ui";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {InvoiceNew} from "../models/InvoiceNew";
import {
  ConfirmacacaoInclusaoCartaoComponent
} from "./confirmacacao-inclusao-cartao/confirmacacao-inclusao-cartao.component";
import {SucessoInclusaoCartaoComponent} from "./sucesso-inclusao-cartao/sucesso-inclusao-cartao.component";
export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.css']
})
export class CreditCardFormComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private creditCardService: CreditCardService,
    private router: Router,
    private autenticacaoExpressaService: AutenticacaoExpressaService,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private myProfileService: MyProfileService,
    private matDialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this.token = param['token'];
    });
  }
  public contracts: Array<Contract> = [];
  public token: string = "";
  public valorTotalInadimplente: number = 0;
  public formContract: FormGroup = new FormGroup({
    GEN_CONTRATO: new FormControl([], [Validators.minLength(1)]),
  });
  public formCreditCard: FormGroup = new FormGroup({
    holderName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.minLength(15), Validators.maxLength(16)]),
    cvv: new FormControl('', [Validators.required, Validators.minLength(3)]),
    due: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  public formBillingCard: FormGroup = new FormGroup({
    billingName: new FormControl('', [Validators.required]),
    billingAddress: new FormControl('', [Validators.required]),
    billingNumber: new FormControl('', [Validators.required]),
    billingComplement: new FormControl('', []),
    billingNeighborhood: new FormControl('', [Validators.required]),
    billingCity: new FormControl('', [Validators.required]),
    billingState: new FormControl('', [Validators.required]),
    billingZipCode: new FormControl('', [Validators.required]),
    billingPhone: new FormControl('', [Validators.required]),
    billingEmail: new FormControl('', [Validators.required]),
    billingCpfCnpj: new FormControl('', [Validators.required]),
    acceptTerms: new FormControl(false, [Validators.requiredTrue]),
  });
  public myProfileForm: FormGroup = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    documento: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telCelular: new FormControl('', [Validators.required]),
  });
  public cardIcon = '';
  public dataSourceContrato: MatTableDataSource<Contract> = new MatTableDataSource<Contract>();
  public dataSourceFatura: MatTableDataSource<InvoiceNew> = new MatTableDataSource<InvoiceNew>();
  public columnsFatura: Field[] = [
    {
      fieldOrder: 1,
      fieldName: 'MES_ANO',
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Ref.",
      primaryKey: false,
      openRoute: false,
      pipe: value => value,
    }, {
      fieldOrder: 1,
      fieldName: 'DESCRICAO_FATURA',
      columnClasses: "",
      visible: true,
      isBadge: false,
      canonicalName: "Descrição",
      primaryKey: false,
      openRoute: false,
      pipe: value => value,
    },
    {
      fieldOrder: 1,
      fieldName: 'DATA_VENCIMENTO',
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Vencimento",
      primaryKey: false,
      openRoute: false,
      pipe: value => this.datePipe.transform(value),
    },
    {
      fieldOrder: 1,
      fieldName: 'VALOR_TOTAL',
      columnClasses: "text-right",
      visible: true,
      isBadge: false,
      canonicalName: "Valor total",
      primaryKey: false,
      openRoute: false,
      pipe: value => this.currencyPipe.transform(value),
    },
    {
      fieldOrder: 1,
      fieldName: 'INADIMPLENTE',
      columnClasses: "text-center",
      visible: true,
      isBadge: true,
      canonicalName: "Situação",
      primaryKey: false,
      openRoute: false,
      pipe: value => value,
      badgeConfig: (value: InvoiceNew) => {
        return [
          {
            text: value.INADIMPLENTE ? "Inadimplente" : value.DATA_PAGAMENTO == null ? "Aguardando pagamento" : "Pago",
            color: value.INADIMPLENTE ? "bg-danger" : value.DATA_PAGAMENTO == null ? "bg-info" : "bg-success",
          }
        ] as FieldBadge[];
      }
    },
  ];
  public columnsContrato: Field[] = [
    {
      fieldOrder: 1,
      fieldName: 'GEN_CONTRATO',
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Contrato",
      primaryKey: true,
      openRoute: false,
      pipe: value => value,
    },
    {
      fieldOrder: 1,
      fieldName: 'DESCRICAO_LOJA',
      columnClasses: "",
      visible: true,
      isBadge: false,
      canonicalName: "Unidade",
      primaryKey: false,
      openRoute: false,
      pipe: value => value,
    },
    {
      fieldOrder: 1,
      fieldName: 'DIA_VENCIMENTO',
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Vencimento",
      primaryKey: false,
      openRoute: false,
      pipe: value => 'Todo dia ' + value,
    },
    {
      fieldOrder: 1,
      fieldName: 'VALOR_MENSALIDADE',
      columnClasses: "text-right",
      visible: true,
      isBadge: false,
      canonicalName: "Mensalidade",
      primaryKey: false,
      openRoute: false,
      pipe: value => this.currencyPipe.transform(value),
    },
    {
      fieldOrder: 1,
      fieldName: 'INADIMPLENCIA',
      columnClasses: "text-center",
      visible: true,
      isBadge: true,
      canonicalName: "Situação",
      primaryKey: false,
      openRoute: false,
      pipe: value => value,
      badgeConfig: (value: Contract) => {
        return [
          {
            text: value.INADIMPLENCIA ? "Inadimplente" : value.FINALIZAR_CONTRATO_DATA == null ? "Em dia" : "Finalizado",
            color: value.INADIMPLENCIA ? "bg-danger" : value.FINALIZAR_CONTRATO_DATA == null ? "bg-success" : "bg-dark",
          }
        ] as FieldBadge[];
      }
    },
  ];

  async ngOnInit(): Promise<any> {
    try {
      this.creditCardService.showSpinner();
      await this.autenticacaoExpressaService.autenticarToken(this.token);
      await this.loginService.mostrarModalTrocaSenha();
      this.creditCardService.showSpinner();
      const data = await lastValueFrom(this.dashboardService.getStatistics());
      this.valorTotalInadimplente = data.TOTAL_MOVIMENTACOES_INADIMPLENTES;
      this.contracts = data.CONTRATOS;
      const profile = await lastValueFrom(this.myProfileService.getMyProfile());
      this.myProfileForm.patchValue({
        nome: profile.NOME,
        documento: profile.CODIGO_TIPO_PESSOA == "F" ?  profile.CPF : profile.CNPJ,
        email: profile.EMAIL,
        telCelular: profile.TEL_CELULAR,
      });
      this.dataSourceContrato.data = data.CONTRATOS;
      this.dataSourceFatura.data = data.MOVIMENTACOES_INADIMPLENTES;
    } catch (e) {
      console.error(e);
    } finally {
      this.creditCardService.hideSpinner();
    }
  }


  public getCreditCardFlag(): string {
    let cardNumber = this.formCreditCard.get('number')?.value;
    cardNumber = cardNumber.replace(/\s|-/g, '');
    const cardRegex: { [key: string]: RegExp } = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
      elo: /^6(?:001|0[0-9]{2}|5[0-9]{2})[0-9]{12}$/,
    };
    for (const flag in cardRegex) {
      if (cardRegex[flag].test(cardNumber)) {
        this.cardIcon = flag;
        return '';
      }
    }
    return '';
  }

  public async fillCep(): Promise<any>{
    const cep = this.formBillingCard.get('billingZipCode')?.value;
    try {
      this.dashboardService.showSpinner();
      const data = await lastValueFrom(this.dashboardService.cep(cep));
      const x = {
        billingAddress: data.logradouro,
        billingCity: data.localidade,
        billingState: data.uf,
        billingNeighborhood: data.bairro,
      };
      this.formBillingCard.patchValue(x);
    } catch (e) {

    } finally {
      this.dashboardService.hideSpinner();
    }
  }

  public async post(): Promise<any> {
    const confirmacao = await lastValueFrom(this.matDialog.open(ConfirmacacaoInclusaoCartaoComponent, {
      data: this.valorTotalInadimplente,
    }).afterClosed());
    if(confirmacao){
      const due = this.formCreditCard.get('due')?.value.toString();
      const load = {
        BillingAddress: this.formBillingCard.get('billingAddress')?.value,
        BillingCity: this.formBillingCard.get('billingCity')?.value,
        BillingComplement: this.formBillingCard.get('billingComplement')?.value,
        BillingCpfCnpj: this.formBillingCard.get('billingCpfCnpj')?.value,
        BillingEmail: this.formBillingCard.get('billingEmail')?.value,
        BillingName: this.formBillingCard.get('billingName')?.value,
        BillingNeighborhood: this.formBillingCard.get('billingNeighborhood')?.value,
        BillingNumber: this.formBillingCard.get('billingNumber')?.value,
        BillingPhone: this.formBillingCard.get('billingPhone')?.value,
        BillingState: this.formBillingCard.get('billingState')?.value,
        BillingZipCode: this.formBillingCard.get('billingZipCode')?.value,
        CardVerificationValue: this.formCreditCard.get('cvv')?.value,
        Month: due.substring(0, 2),
        Name: this.formCreditCard.get('holderName')?.value,
        Number: this.formCreditCard.get('number')?.value,
        Year: due.substring(2),
      } as CreditCardRequest;
      try {
        this.creditCardService.showSpinner();
        await lastValueFrom(this.creditCardService.createCreditCard(load));
        this.creditCardService.hideSpinner();
        await lastValueFrom(this.matDialog.open(SucessoInclusaoCartaoComponent).afterClosed());
        await this.router.navigate(['/principal/dashboard']);
      } catch (err) {
        this.creditCardService.hideSpinner();
        console.log(err);
        this.creditCardService.toasterError('Houve um erro ao criar cartão de crédito');
      } finally {
        this.dashboardService.hideSpinner();
      }
    }
  }

  public async exibirTermosUso() {
    await lastValueFrom(this.matDialog.open(TermosUsoRecorrenciaComponent, {

    }).afterClosed());
  }
}
